<template>
<div class="loginContainer">
  <div class="loginbox">
    <div class="logobox">
      <img src="../../assets/logoimg.png" alt="">
      <span>项目管理后台</span>
    </div>
    <a-form-model
      id="components-form-demo-normal-login"
      :form="form"
      ref="ruleForm"
      class="login-form"
      :model="form"
      :rules="rules">
       <a-form-model-item label="" prop="username">
        <a-input
          size="large"
          v-model="form.username"
          placeholder="请输入账号"
        ><a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="" prop="password">
        <a-input-password
          size="large"
          v-model="form.password"
          placeholder="请输入密码"
        ><a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
        </a-input-password>
      </a-form-model-item>
      <a-form-item>
        <a-button type="primary" @click="handleSubmit" class="login-form-button">
          登录
        </a-button>
      </a-form-item>
    </a-form-model>
  </div>
</div>
</template>

<script>
import { goLogin } from "@/api/Interface/login"
export default {
   data() {
    return {
      form: {},
      rules: {
        username: [ {required: true, message: "请输入您的账号",trigger: "blur"}],
        password: [ {required: true, message: "请输入您的密码", trigger: "blur"}],
      },
    };
  },
  methods: {
     handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          goLogin(this.form).then((res) => {
            if (res.data.status === 200) {
              localStorage.clear();
              console.log(res.data.data.TOKEN);
              localStorage.setItem("token", res.data.data.TOKEN);
              this.$router.push("/projectList");
              this.$message.success("登录成功");
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      }); 
    },
  },
};
</script>
<style>
.loginContainer{
  background: linear-gradient(91deg,#f1eefc,#9dc6ff 70%,#a5bcff);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.loginbox{
  width: 40%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, .6);
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  border-radius:8px ;
}
.logobox{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.logobox img{
  width:70px
}
.logobox span{
  font-size: 24px;
  font-weight: bold;
  margin-left: 20px;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}

#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
</style>