<template>
  <div>
    <a-button size="small" @click="goBack" style="margin-bottom:10px">返回</a-button>
    <a-card title=""  class="card">
        <div class="infobox">
          <div>
            <img v-if="datailsData.img" :src=" imgurl + datailsData.img" alt="" class="imglogo">
            <a-icon v-else type="file-image" class="imgicon"/>
          </div>
          <div class="namebox">
            <div>
              <b>{{ datailsData.name ? datailsData.name : '暂无' }}</b>
              <span v-if="datailsData.nameShort">（{{ datailsData.nameShort }}）</span>
            </div>
           
            <div><span class="labeltit">填报日期:</span> {{ datailsData.inputTime ? datailsData.inputTime : '暂无' }}</div>
            <a-space style="margin:6px 0">
              <a-tag v-if="datailsData.itemStatusName" color="#108ee9">{{ datailsData.itemStatusName }}</a-tag>
              <a-tag v-if="datailsData.itemAttributeIdName" color="#108ee9">{{ datailsData.itemAttributeIdName }}</a-tag>
              <a-tag v-if="datailsData.itemTypeIdName" color="#108ee9">{{ datailsData.itemTypeIdName }}</a-tag>
            </a-space>
            <div v-if="datailsData.projectTagList && datailsData.projectTagList.length > 0">
              <span class="labeltit">项目标签:</span>
              <a-tag
                v-for="item in  datailsData.projectTagList"
                :key="item.id"
                :color="item.color"
                >
                {{ item.name }}
              </a-tag>
            </div>
          </div>
        </div>
        <a-descriptions title="" :column="2" border>
          <a-descriptions-item label="项目分类">
            {{ datailsData.classificationIdName ? datailsData.classificationIdName : '暂无' }}
          </a-descriptions-item>
          <a-descriptions-item label="项目投资">
            {{ datailsData.itemMoney ? datailsData.itemMoney + '万元' : '暂无' }}
          </a-descriptions-item>
          <a-descriptions-item label="用地情况(亩)">
            {{ datailsData.yongDi ? datailsData.yongDi : '暂无'}}
          </a-descriptions-item>
          <a-descriptions-item label="项目地址">
            {{ datailsData.addressDetail ? datailsData.addressDetail : '暂无'}}
          </a-descriptions-item>
          <a-descriptions-item label="项目简介" :span="2">
            {{ datailsData.description ? datailsData.description : '暂无' }}
          </a-descriptions-item>
          <a-descriptions-item label="备注" :span="2">
            {{ datailsData.remark ? datailsData.remark : '暂无' }}
          </a-descriptions-item>
        </a-descriptions>
      </a-card>
      <a-card title="" class="card">
        <a-descriptions title="" :column="1">
           <a-descriptions-item label="公司名称">
            {{ datailsData.companyName ? datailsData.companyName : '暂无' }}
          </a-descriptions-item>
          <a-descriptions-item label="投资主体">
            {{ datailsData.touziZhuti ? datailsData.touziZhuti : '暂无' }}
          </a-descriptions-item>
          <a-descriptions-item label="投资主体联系人">
            {{ datailsData.touziContact ? datailsData.touziContact : '暂无' }} {{ datailsData.touziMobile ? datailsData.touziMobile : '' }}
          </a-descriptions-item>
          <a-descriptions-item label="责任处室">
            {{ datailsData.departmentIdName ? datailsData.departmentIdName : '暂无' }}
          </a-descriptions-item>
          <a-descriptions-item label="分管领导">
            {{ datailsData.masterName ? datailsData.masterName : '暂无' }}
          </a-descriptions-item>
          <a-descriptions-item label="联络员">
            {{ datailsData.contactName ? datailsData.contactName : '暂无' }}
          </a-descriptions-item>
        </a-descriptions>
      </a-card>
      <div class="projectDetails">
        <b >项目进度详情:</b>
          {{ datailsData.itemProgressDesc ? datailsData.itemProgressDesc : '暂无' }}
      </div>
      <a-steps direction="vertical">
        <a-step
          v-for="item in datailsData.itemProgressDetailList"
          :key="item.id"
          :status="item.detailStatus == 1 ? true : false"
          :title="item.progressName"
          :description="item.time" />
      </a-steps>
  </div>
</template>
<script>
import { projectByid } from "@/api/Interface/projectList"
export default {
  data() {
    return {
      current: 0,
      datailsData:'',
      imgurl: process.env.VUE_APP_BASE_IMG_URL //图片地址
    }
  },
  created(){
    this.projectByidFun();// 项目列表
  },
  methods: {
    // 获取列表
    projectByidFun(){
      let obj ={
        itemId:this.$route.params.id
      }
      projectByid(obj).then(res =>{
        if(res.data.status === 200){
          this.datailsData = res.data.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 返回上一页
		goBack(){
			this.$router.go(-1);
		}
  },
};
</script>
<style scoped>
.imglogo{
  max-width: 200px;
  border-radius:4px ;
  margin-right: 30px;
}
.infobox{
  display: flex;
  margin-bottom: 10px;
}
.imgicon{
  font-size: 100px;
  color: #d2d2d2;
  margin-right: 30px;
}
.labeltit{
 color: #8f8f94;
 margin-right: 10px;
}
.card{
  margin-bottom: 10px;
}
.namebox{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.projectDetails{
  background: #b8d4ff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
}
</style>