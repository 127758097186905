<template>
  <div>
    <div class="flexTit">
      <a-button size="small" type="primary" @click="addEditFun()">添加</a-button>
    </div>
   <a-table :columns="columns" :data-source="data" row-key="userId" size="middle">
    <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
      style="padding: 8px">
      <a-input v-ant-ref="c => (searchInput = c)"
        :placeholder="`请输入 ${column.title}`"
        :value="selectedKeys[0]"
        style="width: 188px; margin-bottom: 8px; display: block;"
        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
        @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"/>
      <a-button type="primary" icon="search" size="small" 
                style="width: 90px; margin-right: 8px"
                @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)">
        搜索
      </a-button>
      <a-button size="small" style="width: 90px" 
                @click="() => handleReset(clearFilters)">
        重置
      </a-button>
    </div>
    <a-icon slot="filterIcon" slot-scope="filtered" type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
    />
    <template slot="customRender" slot-scope="text, record, index, column">
      <span v-if="searchText && searchedColumn === column.dataIndex">
        <template v-for="(fragment, i) in text
            .toString()
            .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
        >
        <!-- 婷婷讲解：把输入的东西，先转换成字符串，然后正则表达判断输入框里的东西 -->
        <!-- toLowerCase大小写转换 -->

          <mark
            v-if="fragment.toLowerCase() === searchText.toLowerCase()"
            :key="i"
            class="highlight">{{ fragment }}</mark>
          <template v-else>{{ fragment }}</template>
        </template>
      </span>
      <template v-else>
        {{ text }}
      </template>
    </template>
    <template slot="index" slot-scope="text,record,index">
      {{ index + 1}}
    </template>
    <!--婷婷讲解 slot插槽的名字，slot-scope插槽里要传的数据 text是当前的值，可以随意写 record：这一条表格的数据，-->
    <template slot="password" slot-scope="text,record">
      <a-button type="primary" size="small" @click="reset(record)">重置</a-button>
    </template>
    <template slot="operation" slot-scope="text,record">
      <a-space>
        <span class="icon-wrap">
          <a-icon type="form" @click="addEditFun(record)"/>
        </span>
        <span class="icon-wrap">
          <a-popconfirm
            title="确定要删除吗？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="deletOrigan(record.userId)"
          >
            <a-icon type="delete"/>
          </a-popconfirm>
        </span>
      </a-space>
    </template>  
  </a-table>
  <addEdit :visible='visible' @visibleCancel="visibleCancel" :details="details"/>
      <!--婷婷讲解 组件的调用@visibleCancel，这个可以随便取名 visibleCancel是个方法，也可以随便取名 -->
</div>
  
</template>

<script>
import addEdit from './addEdit'
import { dataList,dataDelete,resetPassword} from "@/api/Interface/personnel"
import { departmentList } from "@/api/Interface/department"
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width:60,
    align: 'center',
    scopedSlots: { customRender: 'index'} 
  },
  {
    title: '用户名',
    key: 'name',
    dataIndex: 'name',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        }, 0);
      }
    },
  },
  {
    title: '手机号',
    key: 'mobile',
    dataIndex: 'mobile',
    align: 'center',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    // 婷婷讲解：onFilter：这个是过滤，相当于一个搜索的功能，没有分页，只能在取当前获取到的数据里搜索
    // includes：搜索包含
    // onFilterDropdownVisibleChange：搜索的时候，要改变的时候
    onFilter: (value, record) =>
      record.mobile
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        });
      }
    },
  },
   {
    title: '短号',
    key: 'shortMobile',
    dataIndex: 'shortMobile',
    align: 'center'
  },
  // {
  //   title: '账号密码',
  //   key: 'bb',
  //   dataIndex: 'bb',
  //   align: 'center'
  // },
  {
    title: '责任处室',
    key: 'departmentName',
    dataIndex: 'departmentName',
    align: 'center',
    filters: [],
    // specify the condition of filtering result
    // here is that finding the name started with `value`
    onFilter: (value, record) => record.departmentName.indexOf(value) === 0,
    sortDirections: ['descend'],
  },
  // {
  //   title: '创建时间',
  //   key: 'dd',
  //   dataIndex: 'dd',
  //   align: 'center'
  // },
  { 
    title: '重置密码', 
    key: 'password',
    scopedSlots: { customRender: 'password'} 
    // 婷婷讲解：scopedSlots：这个是定义了插槽的名字，
    
  },
  { 
    title: '操作', 
    key: 'operation',
    scopedSlots: { customRender: 'operation'} 
  },
];
export default {
  name: 'UserManager',
  components: {
    addEdit
  },
  data(){
    return{
      columns,
      data: [],
      form:{},
      loading: false,
      visible: false,
      details: {},//ID
      searchText: '',
      searchInput: null,
      searchedColumn: '',
    };
  },
  created(){
    this.dataListFun();
    this.departmentListFun();//获取责任处室列表
  },
  methods: {
     // 获取责任处室列表
     
    departmentListFun(){
      departmentList().then(res =>{
        if(res.data.status === 200){
          this.columns[4].filters = res.data.data.map(item=>{
            return{
              text: item.name,
              value: item.name,
            }
          })
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 获取列表
    dataListFun(){
      dataList().then(res =>{
        if(res.data.status === 200){
          this.data = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    //添加修改
    addEditFun(item){
			this.details =   item ? item : {}
			this.visible = true
    },
    // 弹框回调
		visibleCancel(val){
			this.visible = false;
			if(val){
				this.dataListFun();
			}
		},
    //删除
		deletOrigan(userId) {
			dataDelete({userId}).then(res =>{
				if(res.data.status === 200){
					this.$message.success('删除成功');
					this.dataListFun();
				}else{
					this.$message.error(res.data.msg);
				}
			})
    },
    //搜索
    handleSubmit(){
      this.dataListFun();
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = '';
    },
    reset(e){
      resetPassword({userId:e.userId}).then((res)=>{
        if(res.data.status==200){
					this.$message.success('重置成功');
        }
      })
    }
  }
};
</script>

<style  scoped>
.flexTit{
  display: flex;
  justify-content:flex-end;
  align-items: center;
}
</style>