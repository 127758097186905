import { render, staticRenderFns } from "./addfile.vue?vue&type=template&id=7d84fae0&scoped=true&"
import script from "./addfile.vue?vue&type=script&lang=js&"
export * from "./addfile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d84fae0",
  null
  
)

export default component.exports