import {axiosPost,axiosGet} from "../axios"


// 1添加
export function dataAdd(data){
  return axiosPost('/admin/banner/add',data);
}
// 2列表
export function dataList(data){
  return axiosGet('/admin/banner/list',data);
}

// 4编辑
export function dataEdit(data){
  return axiosPost('/admin/banner/edit',data);
}
// 5删除
export function dataDelete(data){
  return axiosPost('admin/banner/del',data);
}

