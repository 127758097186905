<template>
		<a-modal
			:visible="visible"
			:title="Title"
			:confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
			width="500px"
			>
		<a-form-model
			ref="ruleForm"
			:model="form">
			<a-form-model-item prop="policyIcon">
				<addImage :imgUrl='form.img' @success="imageLogo" style="width:104px;margin: 0 auto;"/>
			</a-form-model-item>
		</a-form-model>
		<a-form-item label="链接">
      <a-input v-model="form.jumpUrl" placeholder="请输入链接"/>
    </a-form-item>
	</a-modal>
</template>
<script>
import { dataAdd, dataEdit} from "@/api/Interface/banner" //接口
import addImage from '../components/addImage'
export default {
  data() {
    return {
			Title:'添加',
			loading: false,
			form:{},
			imgurl: process.env.VUE_APP_BASE_IMG_URL //图片地址
    };
  },
	components: {
		addImage,
  },
	props:['visible','details'],
	watch: {
		details(val){
			this.Title = val.img ? '修改' : '添加',
			this.form = val.img ? val : {}
		}
	},
	mounted(){
	},
  methods: {
		//提交 添加 修改
    handleOk() {
			if (this.form.img) {
				this.loading = true;
				// 请选择图片
				this.details.id ? this.dataEidtFun() : this.dataAddFun();
			} else {
				this.$message.success('请选择图片');
				return false;
			}
    },
		// 添加
		dataAddFun(){
			dataAdd(this.form).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功');
					this.$emit('visibleCancel',true);
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
		// 修改
		dataEidtFun(){
			let obj ={
				bannerId:this.details.id,
				...this.form
			}
			dataEdit(obj).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功');
					this.$emit('visibleCancel',true);
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
		// logo图片上传成功回调
		imageLogo(val){
			this.form.img = val
		},
    handleCancel() {
      this.$emit('visibleCancel');
		},
  },
};
</script>
<style scoped>
	.uploader{
		margin:0 auto
	}
</style>
