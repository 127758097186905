import axios from 'axios';
import qs from 'qs';
import router from '@/router'
import { message } from 'ant-design-vue';


const { VUE_APP_BASE_API_URL ,NODE_ENV} = process.env; //获取环境和地址
const baseURL = VUE_APP_BASE_API_URL ;
console.log(VUE_APP_BASE_API_URL,NODE_ENV,'环境');
const request = axios.create({
  baseURL:baseURL,
  paramsSerializer(params){
    return qs.stringify(params, {arrayFormat: 'repeat'});
  }
});

//请求
request.interceptors.request.use((config)=>{
  const token  = localStorage.getItem("token");
  if(token){
    config.headers['token'] = token;
  }
  return config;
});

// http response 拦截器
request.interceptors.response.use(
  res => {
    if (res.data.code === 200) {
      return res
    }else if (res.data.code === 403) {
      // message.destroy();
      // message.error({
      //     content: res.data.message
      // });
      setTimeout(() => {
        router.push("/403")
      }, 0)
    } else {
      return res
    }
  },
  error => {
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
      message.destroy();
      message.warning({
        content: "请求超时",
        duration: 3
      });
    }
    return Promise.reject(error)
  }
);
export function axiosPost(url, data) {
  return new Promise((resolve, reject) => {
    request.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => {
        resolve(response)
    })
    .catch(err => {
        reject(err)
    })
  })
}

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function axiosGet(url, params = {}) {
  return new Promise((resolve, reject) => {
      request.get(url, {
        params: params
      })
      .then(response => {
          resolve(response)
      })
      .catch(err => {
          reject(err)
      })
  })
}
export default request;

