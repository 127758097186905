<template>
  <div>
    <div class="flexTit">
      <a-form-model layout="inline" :form="form" @submit="handleSubmit">
        <a-form-model-item>
          <a-input placeholder="输入项目名或企业名" v-model="form.name" size="small"  style="width:170px"  allowClear/>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            placeholder="项目标签"
            v-model="form.itemTag"
            allowClear
            size="small"
            style="width:120px">
            <a-select-option
              v-for="(item) in labelList"
              :key="item.id"
              :value="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        
        <a-form-model-item >
          <a-select
            placeholder="项目分类"
            v-model="form.classificationId"
            allowClear
            size="small"
            style="width:120px"
            @change="callback">
            <a-select-option
              v-for="(item) in classificationdataList"
              :key="item.classificationId"
              :value="item.classificationId">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item >
          <a-select
            placeholder="项目状态"
            v-model="form.itemStatus"
            allowClear
            size="small"
            style="width:110px">
            <a-select-option
              v-for="(item) in statusList"
              :key="item.itemStatusId"
              :value="item.itemStatusId">
              {{item.itemStatusName}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            placeholder="项目属性"
            v-model="form.itemAttribute"
            allowClear
            size="small"
            style="width:110px">
            <a-select-option
              v-for="(item) in attributeList"
              :key="item.id"
              :value="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
         <a-form-model-item>
          <a-select
            placeholder="项目类别"
            v-model="form.itemType"
            allowClear
            size="small"
            style="width:140px">
            <a-select-option
              v-for="(item) in typeList"
              :key="item.id"
              :value="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            placeholder="请选择责任处室"
            v-model="form.departmentId"
            style="width:160px"
            allowClear>
            <a-select-option
              v-for="(item,index) in departmentListdata"
              :key="index"
              :value="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="">
          <a-cascader
            style="width:320px"
            :field-names="{ label: 'name', value: 'idStr', children: 'userIdList' }"
            :options="departmentDuListData"
            placeholder="请选择联络员"
            @change="(value)=>changeMaster(value)"
            allowClear />
        </a-form-model-item>
        <a-form-model-item >
          <a-range-picker
            @change="onChangeTime"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"/>
        </a-form-model-item>
        <a-form-item>
          <a-button type="primary" html-type="submit" size="small">
            搜索
          </a-button>
        </a-form-item>
      </a-form-model>
    </div>
    <div class="flexbox">
      <a-space>
        <router-link :to="{path:'/projectAdd'}">
          <a-button size="small" type="primary">添加项目</a-button>
        </router-link>
        <!-- <a-button size="small" type="primary">项目导入</a-button>
        <a-button size="small" type="primary">下载导入模板</a-button> -->
        <a-button size="small" type="primary" @click="addEditFunExport">项目动态导出</a-button>
        <a-button size="small" type="primary" @click="addEditFunExportTotal">汇总表导出</a-button>
      </a-space>
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      row-key="id"
      @expand="expandTable" 
      :scroll="{ x: 'calc(300px + 50%)'}"
    >
      <template slot="operation" slot-scope="text,record">
        <a-space>
          <router-link :to="{path:`/projectDetails/${record.id}`}">
            <a-icon type="container" />
          </router-link>
          <router-link :to="{path:`/projectAdd?id=${record.id}`}">
            <a-icon type="form"/>
          </router-link>
          <a-popconfirm
            title="确定要删除吗？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="deletOrigan(record.id)"
          >
            <a-icon type="delete"/>
          </a-popconfirm>
          </a-space>
      </template>
      <a-table
        slot="expandedRowRender"
        :columns="innerColumns"
        :data-source="ProgressList"
        :pagination="false"
         row-key="id"
      >
        <template slot="detailStatusName"  slot-scope="text,record">
          <span v-if="record.detailStatus == 0" style="color:#f00">
            {{text}}
          </span>
          <span v-else style="color:#06bf04">
            {{text}}
          </span>
        </template>
      </a-table>
    </a-table>
    <exportForm
			:visible='visible'
      :formData="form"
			@visibleCancel="visibleCancelExport"/>
  </div>
</template>
<script>
import { projectList , projectDelete, projectProgress, departmentDuList} from "@/api/Interface/projectList"
import { classificationList,itemStatusList ,itemAttributeList, itemTypeList} from "@/api/Interface/projectSort"
import { dataList } from "@/api/Interface/projectLabel"
import { departmentList } from "@/api/Interface/department"
import exportForm from './exportForm'
const columns = [
  { title: '项目名称', dataIndex: 'name',width:120, key: 'name', ellipsis: true },
  { title: '投资主体', dataIndex: 'touziZhuti',width:100, key: 'touziZhuti' , ellipsis: true},
  { title: '项目简介', dataIndex: 'description',width:200, key: 'description', ellipsis: true },
  { title: '用地情况(亩)', dataIndex: 'yongDi', key: 'yongDi', ellipsis: true },
  { title: '总投资（万元）', dataIndex: 'itemMoney', key: 'itemMoney' },
  { title: '当前情况', dataIndex: 'itemProgressDesc', key: 'itemProgressDesc' , ellipsis: true},
  { title: '分管领导', dataIndex: 'masterName', key: 'masterName'},
  { title: '备注', dataIndex: 'remark', key: 'remark' , ellipsis: true},
  { title: '操作', key: 'operation', scopedSlots: { customRender: 'operation' } },
];

const innerColumns = [
  { title: '项目进程', dataIndex: 'progressName', key: 'progressName' },
  { title: '项目状态', dataIndex: 'detailStatusName', key: 'detailStatusName', scopedSlots: { customRender: 'detailStatusName' } },
  { title: '日期', dataIndex: 'time', key: 'time' },
  { title: '内容', dataIndex: 'remark', key: 'remark' },
];

export default {
  data() {
    return {
      form:{},
      data:[],
      columns,
      innerColumns,
      ProgressList:[],
      labelList: [],//下拉列表
      classificationdataList:[],// 项目分类
      statusList:[],// 项目状态
      attributeList:[],// 项目属性
      typeList:[],// 项目类别
      departmentDuListData:[],// 通讯录
      departmentListdata:[],// 责任处事
      visible: false ,// 汇总表导出显示
      commonUrl: process.env.VUE_APP_BASE_API_URL
    };
  },
  components:{
    exportForm
  },
  created(){
    this.labelListFun(); // 获取标签列表
    this.projectListFun();// 项目列表
    this.classificationListFun();//1获取项目分类
    this.departmentDuListFun()//查通讯录
    this.departmentListFun();// 获取责任处室列表
  },
  methods: {
    // 获取列表
    projectListFun(){
      projectList({...this.form}).then(res =>{
        if(res.data.status === 200){
          this.data = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    //查通讯录
    departmentDuListFun(){
      departmentDuList().then(res =>{
        if(res.data.status === 200){
          this.departmentDuListData = res.data.data.map((item)=>{
            return {
              idStr: item.id,
              ...item
            }
          });
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 获取责任处室列表
    departmentListFun(){
      departmentList().then(res =>{
        if(res.data.status === 200){
          this.departmentListdata = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    onChangeTime(dates) {
      this.form.startTime = dates[0]
      this.form.endTime = dates[1]
    },
    changeMaster(value) {
      this.form.contact = value ? value[1].toString() : ''
    },
    //单个项目的流程经度条
    projectProgressFun(itemId){
      projectProgress({itemId}).then(res =>{
        if(res.data.status === 200){
          this.ProgressList = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 获取标签列表
    labelListFun(){
      dataList().then(res =>{
        if(res.data.status === 200){
          this.labelList = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    callback(key) {
      if(key){
        this.itemStatusListFun(key);//获取项目状态
        this.itemAttributeListFun(key)//获取项目属性
        this.itemTypeListFun(key)//获取项目类别
      }
    },
    // 1获取项目分类
    classificationListFun(){
      classificationList().then(res =>{
        if(res.data.status === 200){
          this.classificationdataList = res.data.data;
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 2获取项目状态
    itemStatusListFun( classificationId ){
      itemStatusList({classificationId }).then(res =>{
        if(res.data.status === 200){
          this.statusList = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 3 获取项目属性
    itemAttributeListFun( classificationId ){
      itemAttributeList({ classificationId }).then(res =>{
        if(res.data.status === 200){
          this.attributeList = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 4 项目类别
    itemTypeListFun(classificationId){
      itemTypeList({ classificationId }).then(res =>{
        if(res.data.status === 200){
          this.typeList = res.data.data;
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },

    //添加修改
    addEditFun(type,item){
      this.details =  {
        type,// 类型，1 属性 2，类别，3流程
        id: item ? item.id :'',
        name: item ? item.name :'',
        itemTypeId: this.form.projectType,
        classificationId: this.activeKey
      }
			this.visible = true
    },
    //删除
		deletOrigan(id) {
			projectDelete({id}).then(res =>{
				if(res.data.status === 200){
					this.$message.success('删除成功');
					this.projectListFun();// 项目列表
				}else{
					this.$message.error(res.data.msg);
				}
			})
    },
    handleSubmit(e) {
      e.preventDefault();
      this.projectListFun();// 项目列表
    },
    //展开行
    expandTable(expanded, record){
      console.log(expanded, record,record.id);
      this.projectProgressFun(record.id);
    },
    //汇总表导出
    addEditFunExport(){
        let obj = {	...this.form	}
        let classificationId = obj.classificationId ? obj.classificationId:''
        let itemStatus = obj.itemStatus ?obj.itemStatus:''
        let itemAttribute = obj.itemAttribute ? obj.itemAttribute:''
        let itemType = obj.itemType ? obj.itemType:''

        let name = obj.name ? obj.name:''
        let contact = obj.contact ? obj.contact:''
        let startTime = obj.startTime ? obj.startTime:''
        let endTime = obj.endTime ? obj.endTime:''
        const link = document.createElement('a');
        link.href = `${this.commonUrl}/item/dynamic/export?classificationId=${classificationId}&itemStatus=${itemStatus}&itemAttribute=${itemAttribute}&itemType=${itemType}&name=${name}&contact=${contact}&startTime=${startTime}&endTime=${endTime}`
        link.target = "_blank" 
        document.body.appendChild(link);
        link.click();
        link.remove();
      // http://kaifawei-api.zyxfkj.cn/item/list/export?classificationId=1&itemStatus=1&itemAttribute=2&itemType=5&columnStr=
        this.$refs.ruleForm.resetFields()
        this.$emit('visibleCancel',true);
        this.loading = false;
    },
    addEditFunExportTotal(){
      this.visible = true
    },
    // 汇总表导出弹框回调
		visibleCancelExport(){
			this.visible = false;
		},
  }
};
</script>
<style scoped>
.bannerimg{
  height: 40px;
}
.flexTit{
  display: flex;
  justify-content: space-between;
}
.flexbox{
 display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>