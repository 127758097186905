<template>
  <div>
    <div class="flexTit">
      <a-button size="small" type="primary" @click="addEditFun()">添加</a-button>
    </div>
   <a-table
    :columns="columns"
    :data-source="data"
    row-key="id"
    size="middle">
    <template slot="index" slot-scope="text,record,index">
      {{ index + 1}}
    </template>
    <template slot="dengji" slot-scope="text,record">
      <a-badge :color="record.color" /> {{ text }}
    </template>
    <template slot="operation" slot-scope="text,record">
      <a-space>
        <span class="icon-wrap">
          <a-icon type="form" @click="addEditFun(record)"/>
        </span>
        <span class="icon-wrap">
          <a-popconfirm
            title="确定要删除吗？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="deletOrigan(record.id)"
          >
            <a-icon type="delete"/>
          </a-popconfirm>
        </span>
      </a-space>
    </template>  
  </a-table>
  <addEdit
			:visible='visible'
			@visibleCancel="visibleCancel"
			:details="details"/>
</div>
  
</template>

<script>
import addEdit from './addEdit'
import { dataList,dataDelete } from "@/api/Interface/projectLabel"
const columns = [
   {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width:60,
    align: 'center',
    scopedSlots: { customRender: 'index'} 
  },
  {
    title: '项目等级',
    key: 'name',
    dataIndex: 'name',
    align: 'center',
    scopedSlots: { customRender: 'dengji'} 
  },
  { 
    title: '操作', 
    key: 'operation',
    scopedSlots: { customRender: 'operation'} 
  },
];
export default {
  name: 'UserManager',
  components: {
    addEdit
  },
  data(){
    return{
      data:[],
      columns,
      loading: false,
      visible: false,
      details: {},//ID
    };
  },
  created(){
    this.dataListFun();
  },
  methods: {
    // 获取列表
    dataListFun(){
      dataList().then(res =>{
        if(res.data.status === 200){
          this.data = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    //添加修改
    addEditFun(item){
			this.details = item ? item : {}
			this.visible = true
    },
    // 弹框回调
		visibleCancel(val){
			this.visible = false;
			if(val){
				this.dataListFun();
			}
		},
    //删除
		deletOrigan(id) {
			dataDelete({id}).then(res =>{
				if(res.data.status === 200){
					this.$message.success('删除成功');
					this.dataListFun();
				}else{
					this.$message.error(res.data.msg);
				}
			})
    },
   
  }
};
</script>

<style  scoped>
.flexTit{
  display: flex;
  justify-content: flex-end;
}
</style>