<template>
		<a-modal
			:visible="visible"
			:title="Title"
			:confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
			>
		<a-form-model
			ref="ruleForm"
			:model="form"
			:rules="rules"
			layout="horizontal"
			:label-col=" { span: 6 }"
			:wrapper-col=" { span: 14 }">
			<a-form-model-item label="图标" prop="img">
				<addImage :imgUrl='form.img' @success="imageLogo" style="width:104px;margin: 0 auto;"/>
			</a-form-model-item>
			<a-form-model-item label="标题" prop="title">
				<a-input v-model="form.title" placeholder="请输入标题" allowClear />
			</a-form-model-item>
			<a-form-model-item  label="文件" prop="departmentManagers">
			<addFile :imgUrl='form.file' @success="FileSuccess" :type='1'/>
			</a-form-model-item>
		</a-form-model>
	</a-modal>
</template>
<script>
import { dataAdd, dataEdit} from "@/api/Interface/policy" //接口
import addImage from '../components/addImage'
import addFile from '../components/addfile'
export default {
  data() {
    return {
			Title:'添加',
			loading: false,
			form:{},
			rules: {
        img: [{ required: true, message: '请上传图标', trigger: 'blur' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
      },
			imgurl: process.env.VUE_APP_BASE_IMG_URL, //图片地址
			headers: {
        authorization: 'authorization-text',
      },
    };
  },
	components: {
		addImage,
		addFile
  },
	props:['visible','details'],
	watch: {
		details(val){
			this.Title = val.id ? '修改' : '添加',
			this.form = val ? val : {}
		}
	},
	mounted(){
	},
	methods: {
		//提交 添加 修改
    handleOk() {
			this.$refs.ruleForm.validate(valid => {
        if (valid) {
					this.loading = true;
					this.details.id?this.dataEidtFun():this.dataAddFun();
				} else {
          console.log('error submit!!');
          return false;
        }
      });
    },
		// 添加
		dataAddFun(){
			dataAdd(this.form).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功');
					// this.$refs.ruleForm.resetFields();
					this.$emit('visibleCancel',true);
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
		// 修改
		dataEidtFun(){
			let obj ={
				departmentId:this.details.id,
				...this.form
			}
			dataEdit(obj).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功');
					// this.$refs.ruleForm.resetFields();
					this.$emit('visibleCancel',true);
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
    handleCancel() {
			this.$refs.ruleForm.resetFields();
      this.$emit('visibleCancel');
		},
		// logo图片上传成功回调
		imageLogo(val){
			this.form.img = val
		},
		// 文件图片上传成功回调
		FileSuccess(val){
			this.form.file = val
		},
		handleChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
  },
};
</script>
<style scoped>

</style>