<template>
  <div>
    <a-tabs v-model="activeKey"  @change="callback">
      <a-tab-pane
        v-for="pane in classificationdataList"
        :key="pane.classificationId"
        :tab="pane.name"
        :closable="false"
        >
        <a-descriptions title="">
           <a-descriptions-item label="项目状态" :span="3">
            <span
              class="margin30"
              v-for="(item) in statusList"
              :key="item.itemStatusId">
              {{ item.itemStatusName }}
            </span>
          </a-descriptions-item>
          <a-descriptions-item label="项目类别" :span="3">
            <div>
              <span
                class="margin30"
                v-for="(item) in typeList"
                :key="item.id">
                <span class="margin10">{{ item.name }}</span>
                <a-space>
                  <a-icon type="form" class="deloicon" @click="addEditFun(2,item)"/>
                  <a-popconfirm
                    title="确定要删除吗？"
                    ok-text="确定"
                    cancel-text="取消"
                    @confirm="deleteDataT(item.id)"
                  >
                    <a-icon type="delete" class="deloicon"/>
                  </a-popconfirm>
                </a-space>
              </span>
              <a-tag style="color: #1890ff; borderStyle: dashed;" @click="addEditFun(2)">
                <a-icon type="plus" /> 添加类别
              </a-tag>
            </div>
          </a-descriptions-item>
          <a-descriptions-item label="项目属性" :span="3">
            <a-radio-group v-model="form.projectAttribute" @change="onChange">
              <a-radio
                v-for="(item) in attributeList"
                :key="item.id"
                :value="item.id"
                class="radioStyle">
                <span class="margin10">{{ item.name }}</span>
                  <a-space>
                  <a-icon type="form"  class="deloicon" @click="addEditFun(1,item)"/>
                   <a-popconfirm
                    title="确定要删除吗？"
                    ok-text="确定"
                    cancel-text="取消"
                    @confirm="deleteDataA(item.id)"
                  >
                    <a-icon type="delete" class="deloicon"/>
                  </a-popconfirm>
                </a-space>
              </a-radio>
              <a-tag style="color: #1890ff;  borderStyle: dashed;" @click="addEditFun(1)">
                <a-icon type="plus" /> 添加属性
              </a-tag>
            </a-radio-group>
            <div>
            </div>
          </a-descriptions-item>
          <a-descriptions-item label="项目流程" :span="3">
            <span
              v-for="(item) in progressList"
              :key="item.id"
              :value="item.id"
              class="margin30">
              <span class="margin10">{{ item.name }}</span>
                <a-space>
                <a-icon type="form"  class="deloicon" @click="addEditFun(3,item)"/>
                 <a-popconfirm
                    title="确定要删除吗？"
                    ok-text="确定"
                    cancel-text="取消"
                    @confirm="deleteDataP(item.id)"
                  >
                    <a-icon type="delete" class="deloicon"/>
                  </a-popconfirm>
              </a-space>
            </span>
            <a-tag style="color: #1890ff; borderStyle: dashed;" @click="addEditFun(3)">
              <a-icon type="plus" /> 添加流程
            </a-tag>
          </a-descriptions-item>
        </a-descriptions>
      </a-tab-pane>
    </a-tabs>
     <addEdit
			:visible='visible'
			@visibleCancel="visibleCancel"
			:details="details"/>
  </div>
</template>
<script>
import { classificationList,itemStatusList ,itemAttributeList, itemTypeList, itemProgressList,
itemAttributeDelete, itemTypeDelete, itemProgressDelete
} from "@/api/Interface/projectSort"
import addEdit from './addEdit'

export default {
  data() {
    return {
      activeKey: '',
      classificationdataList:[],// 项目分类
      statusList:[],//项目状态
      attributeList:[],//项目属性
      typeList:[],//项目类别
      progressList:[],//项目流程
      details:{},
      visible:false,
      form:{
        projectAttribute:''
      }
    };
  },
  components: {
    addEdit
  },
  created(){
    this.classificationListFun();//1获取项目分类
  },
  methods: {
    callback(key) {
      this.itemStatusListFun(key);//获取项目状态
      this.itemAttributeListFun(key)//获取项目属性
      this.itemTypeListFun(key)//获取项目类别
    },
    onChange(e) {
      this.form.projectAttribute = e.target.value
      this.itemProgressListFun(e.target.value)
    },
    // 1获取项目分类
    classificationListFun(){
      classificationList().then(res =>{
        if(res.data.status === 200){
          this.classificationdataList = res.data.data;
          const id = this.classificationdataList[0].classificationId;
          this.activeKey = id
          this.callback(id)
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 2获取项目状态
    itemStatusListFun( classificationId ){
      itemStatusList({classificationId }).then(res =>{
        if(res.data.status === 200){
          this.statusList = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 3 获取项目属性
    itemAttributeListFun( classificationId ){
      itemAttributeList({ classificationId }).then(res =>{
        if(res.data.status === 200){
          this.attributeList = res.data.data
          let id = this.attributeList[0].id
          console.log(id,111111);
          this.form.projectAttribute = id;
          this.itemProgressListFun(id);
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 4 项目类别
    itemTypeListFun(classificationId){
      itemTypeList({ classificationId }).then(res =>{
        if(res.data.status === 200){
          this.typeList = res.data.data;
         
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 5获取项目流程
    itemProgressListFun(itemAttributeId ){
      itemProgressList({ itemAttributeId  }).then(res =>{
        if(res.data.status === 200){
          this.progressList = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    //添加修改
    addEditFun(type,item){
      this.details =  {
        type,// 类型，1 属性 2，类别，3流程
        id: item ? item.id :'',
        name: item ? item.name :'',
        itemAttributeId: this.form.projectAttribute,
        classificationId: this.activeKey
      }
			this.visible = true
    },
    // 弹框回调
		visibleCancel(val){
			this.visible = false;
			if(val && val.success){
        val.type == 1 ? this.itemAttributeListFun(this.activeKey) : val.type == 2 ? this.itemTypeListFun(this.activeKey) : this.itemProgressListFun(this.form.projectAttribute);
			}
		},
    //删除
		deleteDataA(id) {
			itemAttributeDelete({id}).then(res =>{
				if(res.data.status === 200){
					this.$message.success('删除成功');
          this. itemAttributeListFun(this.activeKey);//获取项目状态

				}else{
					this.$message.error(res.data.msg);
				}
			})
    },
    //删除
		deleteDataT(id) {
			itemTypeDelete({id}).then(res =>{
				if(res.data.status === 200){
					this.$message.success('删除成功');
					this.itemTypeListFun(this.activeKey)//获取项目属性
				}else{
					this.$message.error(res.data.msg);
				}
			})
    },
    //删除
		deleteDataP(id) {
			itemProgressDelete({id}).then(res =>{
				if(res.data.status === 200){
					this.$message.success('删除成功');
					this.itemProgressListFun(this.form.projectAttribute)
				}else{
					this.$message.error(res.data.msg);
				}
			})
    },
   
  },
};
</script>
<style scoped>
  .radioSpan{
    margin-right: 30px;
  }
  .flexbox{
    display: flex;
  }
  .aRow{
    min-width: 200px;
  }
  .deloicon{
    color: #d9d9d9;
  }
  .margin30{
    margin-right: 30px;
    display: inline-block;
  }
  .margin10{
    margin-right: 10px;
  }
</style>