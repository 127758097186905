<template>
		<a-modal
			:visible="visible"
			:title="Title"
			:confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
			>
		<a-form-model
			ref="ruleForm"
			:model="form"
			:rules="rules"
			layout="horizontal"
			:label-col=" { span: 6 }"
			:wrapper-col=" { span: 14 }">
			<a-form-model-item label="责任处室" prop="name">
				<a-input v-model="form.name" placeholder="请输入责任处室" allowClear />
			</a-form-model-item>
		</a-form-model>
	</a-modal>
</template>
<script>
import { dataAdd, dataEdit} from "@/api/Interface/department" //接口
export default {
  data() {
    return {
			Title:'添加',
			loading: false,
			form:{},
			rules: {
        name: [{ required: true, message: '请输入责任处室', trigger: 'blur' }],
      },
    };
  },
	props:['visible','details'],
	watch: {
		details(val){
			this.Title = val.name ? '修改' : '添加',
			this.form = val.name ? val : {}
		}
	},
	mounted(){
	},
  methods: {
		//提交 添加 修改
    handleOk() {
			this.$refs.ruleForm.validate(valid => {
        if (valid) {
					this.loading = true;
					this.details.id?this.dataEidtFun():this.dataAddFun();
				} else {
          console.log('error submit!!');
          return false;
        }
      });
    },
		// 添加
		dataAddFun(){
			dataAdd(this.form).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功');
					this.$emit('visibleCancel',true);
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
		// 修改
		dataEidtFun(){
			let obj ={
				departmentId:this.details.id,
				...this.form
			}
			dataEdit(obj).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功');
					this.$emit('visibleCancel',true);
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
    handleCancel() {
      this.$emit('visibleCancel');
		},
  },
};
</script>
