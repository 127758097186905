import {axiosPost,axiosGet} from "../axios"


// 1添加
export function dataAdd(data){
  return axiosPost('/admin/department/add',data);
}
// 2列表
export function departmentList(data){
  return axiosGet('/admin/department/list',data);
}

// 4编辑
export function dataEdit(data){
  return axiosPost('/admin/department/edit',data);
}
// 5删除
export function dataDelete(data){
  return axiosPost('admin/department/del',data);
}

