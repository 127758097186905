import {axiosPost,axiosGet} from "../axios"


// 1添加
export function projectAdd(data){
  return axiosPost('/admin/item/add',data);
}
// 2列表
export function projectList(data){
  return axiosGet('/admin/item/list',data);
}
// 4编辑
export function projectEdit(data){
  return axiosPost('/admin/item/edit',data);
}
// 5删除
export function projectDelete(data){
  return axiosPost('/admin/item/del',data);
}
// 6详情
export function projectByid(data){
  return axiosGet('/admin/item/detail',data);
}
//通讯录
export function departmentDuList(data){
  return axiosGet('/admin/department/du_list',data);
}
//单个项目的流程经度条
export function projectProgress(data){
  return axiosGet('/admin/item/progress',data);
}
//汇总表导出
export function projectExport(data){
  return axiosGet('/item/list/export',data);
}
