import {axiosPost,axiosGet} from "../axios"


// 1添加
export function dataAdd(data){
  return axiosPost('/admin/porject_tag/add',data);
}
// 2列表
export function dataList(data){
  return axiosGet('/admin/porject_tag/list',data);
}
// 4编辑
export function dataEdit(data){
  return axiosPost('/admin/porject_tag/edit',data);
}
// 5删除
export function dataDelete(data){
  return axiosPost('/admin/porject_tag/del',data);
}