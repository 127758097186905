<template>
  <a-upload
      name="file"
      @change="handleChange"
      :customRequest="doImgUpload"
      :file-list="fileList"
      :multiple="false"
    >
    <a-button> <a-icon type="upload" /> 上传文件</a-button>
  </a-upload>
</template>

<script>
import { fileLowUpload } from "@/api/Interface/index";

export default {
  data() {
    return {
      fileList: [],
      loading: false,
      imgurlCommon: process.env.VUE_APP_BASE_IMG_URL, //图片地址
    };
  },
  props: [ "imgUrl", "type"],
  watch: {
    imgUrl(val) {
      if(val){
        this.fileList = val.split(",").map((item, index) => {
          return {
            uid: index,
            name: item,
            status: "done",
            url: this.imgurlCommon + item,
          };
        });
      }else{
        this.fileList = [];
      }
    },
  },
  methods: {
    handleChange({ fileList }) {
      this.fileList = fileList;
      let updateList = fileList.map((item) => {
        return item.name;
      });
      updateList = updateList.join(",");
      this.$emit("update", updateList);
    },
    // beforeUpload(file) {
      // const isJpgOrPng =
      //   file.type === "image/jpeg" ||
      //   file.type === "image/png" ||
      //   file.type === "image/jpg";
      // if (!isJpgOrPng) {
      //   this.$message.error("只能上传png、jpg、jpeg格式的图片!");
      // }
      // const isLt2M = file.size / 1024 / 1024 < 10;
      // if (!isLt2M) {
      //   this.$message.error("图片尺寸不能超过 10MB!");
      // }
      // return isJpgOrPng && isLt2M;
    // },
    // 上传图片
    doImgUpload(file) {
      this.loading = true
      const formData = new FormData();
      formData.append("file", file.file);
      fileLowUpload(formData).then((res) => {
        if (res.data.status === 200) {
          this.imageUrl = this.imgurlCommon + res.data.data.filePath;
          this.$emit("success", res.data.data.filePath);
          this.$message.success("上传成功");
          this.loading = false
          this.fileList.forEach((item) => {
            item.status = "done";
            item.url = this.imageUrl;
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>
<style  scoped>
</style>