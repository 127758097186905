<template>
		<a-modal
			:visible="visible"
			:title="Title"
			:confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
			>
		<a-form-model
			ref="ruleForm"
			:model="form"
			:rules="rules"
			layout="horizontal">
			<a-form-model-item label="" prop="columnStr">
				<a-checkbox-group v-model="form.columnStr" >
          <a-checkbox
            v-for="(item) in plainOptions"
            :key="item.key"
            :value="item.key" >
            <span style="display:inline-block;min-width:100px;">{{ item.name }}</span>
          </a-checkbox>
        </a-checkbox-group>
			</a-form-model-item>
		</a-form-model>
	</a-modal>
</template>
<script>
// import { projectExport } from "@/api/Interface/projectList"

const plainOptions = [
	{
		name:"项目名称",
		key:"name"
	},
	{
		name:"用地情况(亩)",
		key:"yongDi"
	},
	{
		name:"项目投资(万元)",
		key:"itemMoney"
	},
	{
		name:"项目概况",
		key:"description"
	},
	{
		name:"最新进度详情",
		key:"itemProgressDesc"
	},
	{
		name:"投资主体",
		key:"touziZhuti"
	},
	{
		name:"责任处室",
		key:"departmentName"
	},
	{
		name:"分管领导",
		key:"masterName"
	},
	{
		name:"联络员",
		key:"contactName"
	},
	{
		name:"项目备注",
		key:"remark"
	},
	{
		name:"引进时间",
		key:"time"
	}
];
export default {
  data() {
    return {
			Title:'请选择汇总表导出列',
      plainOptions,
			form:{
				columnStr:[]
			},
			rules: {
        columnStr: [{ required: true, message: '请选择需要导出的列', trigger: 'blur' }],
      },
			loading:false,
			commonUrl: process.env.VUE_APP_BASE_API_URL
    };
  },
	props:['visible','formData'],
  methods: {
		//提交 添加 修改
    handleOk() {
			this.$refs.ruleForm.validate(valid => {
        if (valid) {
					this.form.columnStr = this.form.columnStr.toString(",")
					let obj = {
						...this.formData ,
						...this.form
					}
					console.log(obj);
					let classificationId = obj.classificationId ? obj.classificationId:''
					let itemStatus = obj.itemStatus ?obj.itemStatus:''
					let itemAttribute = obj.itemAttribute ? obj.itemAttribute:''
					let itemType = obj.itemType ? obj.itemType:''
					let columnStr = obj.columnStr ? obj.columnStr:''
				
					let name = obj.name ? obj.name:''
					let contact = obj.contact ? obj.contact:''
					let startTime = obj.startTime ? obj.startTime:''
					let endTime = obj.endTime ? obj.endTime:''
						const link = document.createElement('a');
						// link.download = "汇总表导出.xlsx"
						link.href = `${this.commonUrl}/item/list/export?classificationId=${classificationId}&itemStatus=${itemStatus}&itemAttribute=${itemAttribute}&itemType=${itemType}&name=${name}&contact=${contact}&startTime=${startTime}&endTime=${endTime}&columnStr=${columnStr}`
						document.body.appendChild(link);
						link.click();
						link.remove();

					// http://kaifawei-api.zyxfkj.cn/item/list/export?classificationId=1&itemStatus=1&itemAttribute=2&itemType=5&columnStr=
						this.$refs.ruleForm.resetFields()
						this.$emit('visibleCancel',true);
						this.loading = false;
				} else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCancel() {
			this.$refs.ruleForm.resetFields()
      this.$emit('visibleCancel');
		},
  },
};
</script>
