import {axiosPost} from "../axios"


// 1添加
export function dataAdd(data){
  return axiosPost('/admin/policy/add',data);
}
// 2列表
export function dataList(data){
  return axiosPost('/admin/policy/list',data);
}
// 4编辑
export function dataEdit(data){
  return axiosPost('/admin/policy/edit',data);
}
// 5删除
export function dataDelete(data){
  return axiosPost('/admin/policy/del',data);
}
// 6发布
export function publish(data){
  return axiosPost('admin/policy/publish',data);
}