<template>
		<a-modal
			:visible="visible"
			:title="Title"
			:confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
			>
		<a-form-model
			ref="ruleForm"
			:model="form"
			:rules="rules"
      :label-col=" { span: 6 }"
			:wrapper-col=" { span: 14 }">
			<a-form-model-item :label="lableIpt" prop="name">
				<a-input v-model="form.name" :placeholder="'请输入' + lableIpt" allowClear/>
			</a-form-model-item>
		</a-form-model>
	</a-modal>
</template>
<script>
import { itemAttributeAdd,itemAttributeEdit,itemTypeAdd ,itemTypeEdit,itemProgressAdd,itemProgressEdit} from "@/api/Interface/projectSort"
export default {
  data() {
    return {
			Title:'添加',
      lableIpt:"名称",
			loading: false,
			form: {},
			rules: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
    };
  },
	props:['visible','details'],
	watch: {
		details(val){
			this.Title = val.id ? '修改' : '添加',
      this.lableIpt = val.type == 1 ? '项目属性' : val.type == 2 ? '项目类别': '项目流程'// 类型，1 属性 2，类别，3流程
			this.form = val ? {name: val.name} : {}
		}
	},
	mounted(){
    
	},
  methods: {
		//提交 添加 修改
    handleOk() {
			this.$refs.ruleForm.validate(valid => {
        if (valid) {
					this.loading = true;
          let obj ={
            classificationId:this.details.classificationId,
            name: this.form.name,
          }
          let obj1 ={
            itemAttributeId:this.details.itemAttributeId,
            name: this.form.name,
          }
          if(this.details.type == 1){
            // 项目属性 
            this.details.id ? this.itemAttributeEditFun({...obj, id: this.details.id}) : this.itemAttributeAddFun(obj);
          }else if(this.details.type == 2){
            this.details.id ? this.itemTypeEditFun({...obj, id: this.details.id}) : this.itemTypeAddFun(obj);
          }else if(this.details.type == 3){
            this.details.id ? this.itemProgressEditFun({...obj1, id: this.details.id}) : this.itemProgressAddFun(obj1);
          }
				
				} else {
          console.log('error submit!!');
          return false;
        }
      });
    },
		// 项目属性 添加
		itemAttributeAddFun(obj){
			itemAttributeAdd(obj).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功');
					this.$emit('visibleCancel', { success: true, type: 1 });
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
		// 项目属性 修改
		itemAttributeEditFun(obj){
			itemAttributeEdit(obj).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功');
					this.$emit('visibleCancel', { success: true, type: 1 });
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
    // 项目类型 添加
		itemTypeAddFun(obj){
			itemTypeAdd(obj).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功');
					this.$emit('visibleCancel', { success: true, type: 2 });
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
		// 项目类型 修改
		itemTypeEditFun(obj){
			itemTypeEdit(obj).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功');
					this.$emit('visibleCancel', { success: true, type: 2 });
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
    // 项目流程 添加
		itemProgressAddFun(obj){
			itemProgressAdd(obj).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功');
					this.$emit('visibleCancel', { success: true, type: 3 });
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
		// 项目流程 修改
		itemProgressEditFun(obj){
			itemProgressEdit(obj).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功');
					this.$emit('visibleCancel', { success: true, type: 3 });
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
    handleCancel() {
      this.$emit('visibleCancel');
		},
  },
};
</script>
<style scoped>
  .colorPicker{
    padding: 4px ;
    border:1px solid #e6e6e6;
    vertical-align: middle;
  }
</style>