import {axiosPost,axiosGet} from "../axios"


// 1获取项目分类
export function classificationList(data){
  return axiosGet('admin/classification/list',data);
}

// 2获取项目状态
export function itemStatusList(data){
  return axiosGet('admin/itemStatus',data);
}
// 1 项目属性--增加
export function itemAttributeAdd(data){
  return axiosPost('/admin/itemAttribute/add',data);
}

// 2项目属性--编辑
export function itemAttributeEdit(data){
  return axiosPost('/admin/itemAttribute/edit',data);
}
// 3项目属性--删除
export function itemAttributeDelete(data){
  return axiosPost('admin/itemAttribute/del',data);
}

// 4 获取项目列表
export function itemAttributeList(data){
  return axiosGet('admin/itemAttribute/list',data);
}
// 1 项目类别--增加
export function itemTypeAdd(data){
  return axiosPost('/admin/itemType/add',data);
}

// 2项目类别--编辑
export function itemTypeEdit(data){
  return axiosPost('/admin/itemType/edit',data);
}
// 3项目类别--删除
export function itemTypeDelete(data){
  return axiosPost('admin/itemType/del',data);
}

// 4 项目类别--列表
export function itemTypeList(data){
  return axiosGet('admin/itemType/list',data);
}
// 1 项目流程--增加
export function itemProgressAdd(data){
  return axiosPost('/admin/itemProgress/add',data);
}

// 2 项目流程--编辑
export function itemProgressEdit(data){
  return axiosPost('/admin/itemProgress/edit',data);
}
// 3 项目流程--删除
export function itemProgressDelete(data){
  return axiosPost('admin/itemProgress/del',data);
}

// 4 项目流程--列表
export function itemProgressList(data){
  return axiosGet('admin/itemProgress/list',data);
}