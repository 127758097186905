<template>
		<a-modal
			:visible="visible"
			:title="Title"
			:confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
			>
		<a-form-model
			ref="ruleForm"
			:model="form"
			:rules="rules"
      :label-col=" { span: 6 }"
			:wrapper-col=" { span: 14 }">
			<a-form-model-item label="项目等级" prop="name">
				<a-input v-model="form.name" placeholder="项目等级" allowClear  style="width: 60%; margin-right: 8px"/>
			</a-form-model-item>
      <a-form-model-item label="等级颜色" prop="color">
        <colorPicker v-model="form.color" class="colorPicker" />
			</a-form-model-item>
		</a-form-model>
	</a-modal>
</template>
<script>
import { dataAdd, dataEdit} from "@/api/Interface/projectLabel" //接口
export default {
  data() {
    return {
			Title:'添加',
			loading: false,
			form: {
				color:'#ff0000',
			},
			rules: {
        name: [{ required: true, message: '请输入项目等级', trigger: 'blur' }],
      },
    };
  },
	props:['visible','details'],
	watch: {
		details(val){
			this.Title = val.name ? '修改' : '添加',
			this.form = val.name ? val : {color:'#ff0000'}
		}
	},
	mounted(){
	},
  methods: {
		//提交 添加 修改
    handleOk() {
			this.$refs.ruleForm.validate(valid => {
        if (valid) {
					this.loading = true;
					this.details.id ? this.dataEidtFun() : this.dataAddFun();
				} else {
          console.log('error submit!!');
          return false;
        }
      });
    },
		// 添加
		dataAddFun(){
			dataAdd(this.form).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功');
					this.$emit('visibleCancel',true);
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
		// 修改
		dataEidtFun(){
			let obj ={
				id: this.details.id,
				name: this.form.name,
				color: this.form.color
			}
			dataEdit(obj).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功');
					this.$emit('visibleCancel',true);
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
    handleCancel() {
      this.$emit('visibleCancel');
		},
  },
};
</script>
<style scoped>
  .colorPicker{
    padding: 4px ;
    border:1px solid #e6e6e6;
    vertical-align: middle;
  }
</style>