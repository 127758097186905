import {axiosPost,axiosGet} from "../axios"


// 1添加
export function dataAdd(data){
  return axiosPost('/admin/add',data);
}
// 2列表
export function dataList(data){
  return axiosGet('/admin/list',data);
}
// 重置密码
export function resetPassword(data){
  return axiosPost('/user/init_password',data);
}
// 4编辑
export function dataEdit(data){
  return axiosPost('/admin/edit',data);
}
// 5删除
export function dataDelete(data){
  return axiosPost('admin/del',data);
}

