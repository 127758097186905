<template>
  <div>
    <div class="flexTit">
      <a-button size="small" type="primary" @click="addEditFun()">添加</a-button>
    </div>
   <a-table
    :columns="columns"
    :data-source="data"
    row-key="id"
    size="middle">
    <template slot="index" slot-scope="text,record,index">
      {{ index + 1}}
    </template>
    <template slot="img" slot-scope="text">
      <img :src="imgurl+text" alt="" class="bannerimg">
    </template>
    <template slot="publish" slot-scope="text,record">
      <a-switch 
        checked-children="发布"
        un-checked-children="未发布"
        :defaultChecked="text === 0 ? false  : true"
        :checked="text === 0 ? false : true"
        @change="onChange(record)"></a-switch>
    </template>
    <template slot="file" slot-scope="text">
      <a :href="imgurl+text" target="_blank">{{ text }}</a>
    </template>
    <template slot="operation" slot-scope="text,record">
      <a-space>
        <span class="icon-wrap">
          <a-icon type="form" @click="addEditFun(record)"/>
        </span>
        <span class="icon-wrap">
          <a-popconfirm
            title="确定要删除吗？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="deletOrigan(record.id)"
          >
            <a-icon type="delete"/>
          </a-popconfirm>
        </span>
      </a-space>
    </template>  
  </a-table>
  <addEdit
			:visible='visible'
			@visibleCancel="visibleCancel"
			:details="details"/>
</div>
  
</template>

<script>
import addEdit from './addEdit'
import { dataList, dataDelete , publish} from "@/api/Interface/policy"
const columns = [
   {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width:60,
    scopedSlots: { customRender: 'index'} 
  },
  {
    title: '图标',
    key: 'img',
    dataIndex: 'img',
    align: 'center',
    scopedSlots: { customRender: 'img'} 
  },
  {
    title: '标题',
    key: 'title',
    dataIndex: 'title',
    align: 'center'
  },
  {
    title: '上传文件',
    key: 'file',
    dataIndex: 'file',
    align: 'center',
    scopedSlots: { customRender: 'file'} 
  },
  {
    title: '创建时间',
    key: 'ctime',
    dataIndex: 'ctime',
    align: 'center'
  },
  {
    title: '状态',
    key: 'publish',
    dataIndex: 'publish',
    align: 'center',
    width:120, 
    scopedSlots: { customRender: 'publish'} 
  },
  
  { 
    title: '操作', 
    key: 'operation',
    scopedSlots: { customRender: 'operation'} 
  },
];
export default {
  name: 'UserManager',
  components: {
    addEdit
  },
  data(){
    return{
      columns,
      data: [],
      loading: false,
      visible: false,
      details: {},//ID
      imgurl: process.env.VUE_APP_BASE_IMG_URL ,//图片地址
    };
  },
  created(){
    this.dataListFun();
  },
  methods: {
    // 获取列表
    dataListFun(){
      dataList().then(res =>{
        if(res.data.status === 200){
          this.data = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    //添加修改
    addEditFun(item){
			this.details =   item ? item : {}
			this.visible = true
    },
    // 弹框回调
		visibleCancel(val){
			this.visible = false;
			if(val){
				this.dataListFun();
			}
		},
    //删除
		deletOrigan(id) {
			dataDelete({id}).then(res =>{
				if(res.data.status === 200){
					this.$message.success('删除成功');
					this.dataListFun();
				}else{
					this.$message.error(res.data.msg);
				}
			})
    },
     onChange(item) {
      let data = {
        id: item.id,
        publish: item.publish === 0 ? 1 : 0
      };
      publish(data).then(res=>{
        if(res.data.status === 200){
          this.$message.success('状态修改成功');
          this.dataListFun();
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(err=>{
        console.log(err);
      });
    },
  }
};
</script>

<style  scoped>
.flexTit{
  display: flex;
  justify-content: flex-end;
}
.bannerimg{
  height: 20px;
  max-width: 100px
}
.green{
  color: rgb(18, 133, 94);
}
</style>