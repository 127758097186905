<template>
<div>
	<a-button size="small" @click="goBack">返回</a-button>
	<a-form-model ref="ruleForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
    <a-form-model-item label="项目分类">
      <a-radio-group v-model="form.classificationId" >
				<a-radio
					v-for="(item) in classificationdataList"
					:key="item.classificationId"
					:value="item.classificationId" 
					@change="callback(item.classificationId)">
						<span>{{ item.name }}</span>
				</a-radio>
			</a-radio-group>
    </a-form-model-item>
    <a-form-model-item label="项目名称:" prop="name">
      <a-input v-model="form.name" placeholder="请输入项目名称"/>
    </a-form-model-item>
    <a-form-model-item label="简称:">
      <a-input v-model="form.nameShort" :maxLength="4" placeholder="请输入项目简称"/>
    </a-form-model-item>
    <a-form-model-item label="项目logo:">
			<addImage :imgUrl='form.img' @success="imageLogo" style="width:104px;"/>
      <a-icon v-if="form.img" @click="deleLogo"
              type="close-circle" class="deleIcon"
              :style="{color:'#ff1a1a',fontSize: '20px'}"/>
    </a-form-model-item>
    <a-form-model-item label="项目简介:">
      <a-input v-model="form.description" placeholder="请输入项目简介"/>
    </a-form-model-item>
    <template v-if="form.classificationId">
      <a-form-model-item label="项目属性">
        <a-radio-group v-model="form.itemAttributeId"  @change="onChange">
          <a-radio
            v-for="(item) in attributeList"
            :key="item.id"
            :value="item.id" >
              <span>{{ item.name }}</span>
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="项目类别">
        <a-radio-group v-model="form.itemTypeId">
          <a-radio
            v-for="(item) in typeList"
            :key="item.id"
            :value="item.id" >
              <span>{{ item.name }}</span>
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="项目进程">
        <a-radio-group v-model="form.itemProgressId" >
          <a-radio
            v-for="(item) in progressList"
            :key="item.id"
            :value="item.id" >
              <span>{{ item.name }}</span>
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="项目状态" prop="itemStatus">
        <a-radio-group v-model="form.itemStatus" >
          <a-radio
            v-for="(item) in statusList"
            :key="item.itemStatusId"
            :value="item.itemStatusId" >
              <span>{{ item.itemStatusName }}</span>
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </template>
		<a-form-model-item label="用地情况(亩):" id="labelLight" style="color:#ff0 !important;">
      <a-input v-model="form.yongDi" placeholder="请输入用地情况(亩)"/>
    </a-form-model-item>
		<a-form-model-item label="项目地址:" >
      <a-input v-model="form.addressDetail" placeholder="请输入项目地址"/>
    </a-form-model-item>
    <a-form-model-item label="项目进度详情:" id="labelLight" style="color:#ff0 !important;">
      <a-input v-model="form.itemProgressDesc" placeholder="请输入项目进度详情"/>
    </a-form-model-item>
		<a-form-model-item label="项目标签">
       <a-checkbox-group v-model="form.tagList" >
          <a-checkbox
            v-for="(item) in labelList"
            :key="item.id"
            :value="item.id" >
              <span>{{ item.name }}</span>
          </a-checkbox>
        </a-checkbox-group>
    </a-form-model-item>
    <a-form-model-item label="项目投资（万）:">
      <!--  -->
      <a-input-number
        :min='0'
        v-model="form.itemMoney"
        :step='1'
        :formatter="value => ` ${value}`.replace(/^(1+)|[^\d]+/g, '')"
        placeholder="请输入"
        style="width:300px" />
    </a-form-model-item>
    <a-form-model-item label="投资主体:">
      <a-input v-model="form.touziZhuti" placeholder="请输入项目投资主体"/>
    </a-form-model-item>
    <a-form-model-item label="投资主体联系人:">
      <a-input v-model="form.touziContact" placeholder="请输入项目投资主体联系人"/>
    </a-form-model-item>
    <a-form-model-item label="联系人电话:">
      <a-input v-model="form.touziMobile" placeholder="请输入联系人电话"/>
    </a-form-model-item>
		<a-form-model-item label="责任处室:" prop="departmentId">
			<a-select
				placeholder="请选择"
				v-model="form.departmentId"
				allowClear>
				<a-select-option
					v-for="(item,index) in departmentListdata"
					:key="index"
					:value="item.id">
					{{item.name}}
				</a-select-option>
			</a-select>
    </a-form-model-item>
   
		<a-form-model-item label="分管领导:">
      <span> {{form.masterName}}  </span>
      <a-cascader
        style="width:320px"
        :field-names="{ label: 'name', value: 'idStr', children: 'userIdList' }"
        :options="departmentDuListData"
        placeholder="请选择"
        @change="(value,selectedOptions)=>changeMaster(value,selectedOptions,1)"
        allowClear />
    </a-form-model-item>
		<a-form-model-item label="联络员:" prop="contact">
       <span> {{form.contactName}}  </span>
       <a-cascader
        style="width:320px"
        :field-names="{ label: 'name', value: 'idStr', children: 'userIdList' }"
        :options="departmentDuListData"
        placeholder="请选择"
        @change="(value,selectedOptions)=>changeMaster(value,selectedOptions,2)"
        allowClear />
    </a-form-model-item>
		<a-form-model-item label="填报日期:" prop="inputTime">
      <a-date-picker
        v-model="form.inputTime"
        format="YYYY-MM-DD HH:mm:ss"
        value-format="YYYY-MM-DD HH:mm:ss"
        placeholder="请选择填报日期" />
    </a-form-model-item>
    <a-form-model-item label="备注:">
      <a-input v-model="form.remark" placeholder="请输入项目备注信息"/>
    </a-form-model-item>
    <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
      <a-button type="primary" @click="onSubmit">
        确定
      </a-button>
      <a-button style="margin-left: 10px;"  @click="goBack">
        取消
      </a-button>
    </a-form-model-item>
  </a-form-model>
</div>
  
</template>
<script>
import { projectAdd, projectEdit, departmentDuList, projectByid} from "@/api/Interface/projectList" //接口
import { classificationList, itemStatusList ,itemAttributeList, itemTypeList, itemProgressList} from "@/api/Interface/projectSort"
import addImage from '../components/addImage'
import { dataList } from "@/api/Interface/projectLabel"
import { departmentList } from "@/api/Interface/department"
import moment from 'moment'
export default {
  data() {
    return {
      classificationdataList:[],// 项目分类
			statusList:[],//项目状态
      attributeList:[],//项目属性
      typeList:[],//项目类别
      progressList:[],//项目流程
      labelList:[],// 项目标签
			departmentListdata:[],//责任处事
      departmentDuListData:[],//
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      rules: {
        itemStatus: [{ required: true, message: '请选择项目状态', trigger: 'blur' }],
        name: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
        inputTime: [{ required: true, message: '请添加时间', trigger: 'blur' }],
        departmentId: [{ required: true, message: '请选择责任处室', trigger: 'blur' }],
        contact: [{ required: true, message: '请选择联络员', trigger: 'blur' }],
      },
      form: {
				classificationId:'',
        inputTime:  moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
			},
			projectId:'',//项目id
    };
  },
	components: {
		addImage,
  },
	mounted() {
    this.labelListFun();// 获取项目标签
    this.departmentListFun();// 获取责任处室列表
    this.departmentDuListFun()//查通讯录
    this.projectId = this.$route.query.id;
    this.projectId ? this.projectByidFun() : this.classificationListFun();//1获取项目分类
    
  },
  methods: {
    changeMaster(value,selectedOptions,type) {
      if(type === 1){
        this.form.master = value ? value[1].toString() : ''
        this.form.masterName = selectedOptions? selectedOptions[1].name : ''
      }else{
        this.form.contact = value ? value[1].toString() : ''
        this.form.contactName = selectedOptions? selectedOptions[1].name : ''
      }
    },
     // 获取列表
    projectByidFun(){
      let obj ={
        itemId: this.projectId
      }
      projectByid(obj).then(res =>{
        if(res.data.status === 200){
          this.form = res.data.data.data
          this.classificationListFun();
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
		callback(id) {
      this.itemStatusListFun(id);//获取项目状态
      this.itemAttributeListFun(id)//获取项目属性
      this.itemTypeListFun(id)//获取项目类别
    },
		onChange(e) {
      this.itemProgressListFun(e.target.value)
    },
    //查通讯录
    departmentDuListFun(){
      departmentDuList().then(res =>{
        if(res.data.status === 200){
          this.departmentDuListData = res.data.data.map((item)=>{
            return {
              idStr: item.id,
              ...item
            }
          });
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
		// 1获取项目分类
    classificationListFun(){
      classificationList().then(res =>{
        if(res.data.status === 200){
          this.classificationdataList = res.data.data;
					const id = this.form.classificationId ? this.form.classificationId : this.classificationdataList[0].classificationId;
          this.form.classificationId = id
          this.callback(id)
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 2获取项目状态
    itemStatusListFun( classificationId ){
      itemStatusList({classificationId }).then(res =>{
        if(res.data.status === 200){
          this.statusList = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 3 获取项目属性
    itemAttributeListFun( classificationId ){
      itemAttributeList({ classificationId }).then(res =>{
        if(res.data.status === 200){
          this.attributeList = res.data.data
          let id = this.form.itemAttributeId ? this.form.itemAttributeId :  this.attributeList[0].id
          this.form.itemAttributeId = id;
          this.itemProgressListFun(id);
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 4 项目类别
    itemTypeListFun(classificationId){
      itemTypeList({ classificationId }).then(res =>{
        if(res.data.status === 200){
          this.typeList = res.data.data;
         
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 5获取项目流程
    itemProgressListFun(itemAttributeId){
      itemProgressList({ itemAttributeId }).then(res =>{
        if(res.data.status === 200){
          this.progressList = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
		// 获取项目标签
    labelListFun(){
      dataList().then(res =>{
        if(res.data.status === 200){
          this.labelList = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
		// 获取责任处室列表
    departmentListFun(){
      departmentList().then(res =>{
        if(res.data.status === 200){
          this.departmentListdata = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    //提交 添加 修改
    onSubmit() {
			this.$refs.ruleForm.validate(valid => {
        if (valid) {
					this.loading = true;
					this.projectId ? this.dataEidtFun() : this.dataAddFun();
				} else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 删除logo
    deleLogo(){
      this.form.img=''
    },
		// 添加
		dataAddFun(){
			projectAdd(this.form).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功,将返回列表页');
					this.goBack();
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
		// 修改
		dataEidtFun(){
			projectEdit(this.form).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功,将返回列表页');
					this.goBack();
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
    handleCancel() {
      this.$emit('visibleCancel');
		},
		// logo图片上传成功回调
		imageLogo(val){
			this.form.img = val
		},
		// 返回上一页
		goBack(){
			this.$router.go(-1);
		}
  },
};
</script>
<style scoped>
#labelLight .ant-form-item-label > label{
 color: blue;
}
.deleIcon{
  position: absolute;
  top: 0px;
  left: 100px;
}
</style>