<template>
		<a-modal
			:visible="visible"
			:title="Title"
			:confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
			>
		<a-form-model
			ref="ruleForm"
			:model="form"
			:rules="rules"
			layout="horizontal"
			:label-col=" { span: 6 }"
			:wrapper-col=" { span: 14 }">
			<a-form-model-item label="用户名" prop="name">
				<a-input v-model="form.name" placeholder="请输入用户名" allowClear />
			</a-form-model-item>
			<a-form-model-item label="手机号" prop="mobile">
				<a-input v-model="form.mobile" placeholder="请输入手机号" allowClear />
			</a-form-model-item>
			<a-form-model-item label="责任处室" prop="departmentId">
				<a-select
					placeholder="请选择责任处室"
					v-model="form.departmentId"
					allowClear>
					<a-select-option
						v-for="(item,index) in departmentList"
						:key="index"
						:value="item.id">
						{{item.name}}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="短号">
				<a-input v-model="form.shortMobile" placeholder="请输入短号" allowClear />
			</a-form-model-item>
			<!-- <a-form-model-item label="密码" prop="password">
				<a-input v-model="form.password" type="password" placeholder="请输入密码" allowClear />
			</a-form-model-item> -->
		</a-form-model>
	</a-modal>
</template>
<script>

import { dataAdd, dataEdit} from "@/api/Interface/personnel" //接口
import { departmentList } from "@/api/Interface/department"
export default {
  data() {
    return {
			Title:'添加',
			loading: false,
			form:{},
			departmentList:[],
			rules: {
        name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
				departmentId: [{ required: true, message: '请选择责任处室', trigger: 'change' }],
        shortMobile: [{ required: true, message: '请输入短号', trigger: 'blur' }],
				mobile: [{ required: true, message: '请输入手机号', trigger: 'blur'  }],
				// mobile: [{ required: true, len: 11, pattern: RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/), message: '请输入正确的手机号', trigger: 'blur'  }],
      },
    };
  },
	props:['visible','details'],
	watch: {
		details(val){
			this.Title = val.name ? '修改' : '添加',
			this.form = val ? {...val,departmentId:parseInt(val.departmentId)} : {}
		}
	},
	mounted(){
		// 获取列表
    this.departmentListFun()
	},
  methods: {
		//提交 添加 修改
    handleOk() {
			this.$refs.ruleForm.validate(valid => {
        if (valid) {
					this.loading = true;
					this.details.userId ? this.dataEidtFun() : this.dataAddFun();
				} else {
					this.loading = false;
          console.log('error submit!!');
          return false;
        }
      });
    },
		// 添加
		dataAddFun(){
			dataAdd(this.form).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功');
					// 婷婷讲解：这是一个子组件，添加成功以后，给父组件传递方法，消息，
					// visibleCancel这个是方法名，true是传给父组件的内容，可以是数组对象
					this.$emit('visibleCancel',true);
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
		// 修改
		dataEidtFun(){
			let obj ={
				// departmentId:this.details.id,
				...this.form
				// 婷婷讲解：form是一个表单，。。。是扩展运算符，这么写，就是直接把form直接赋值给obj，
				// 这个相当于一个浅克隆，还有一个深度克隆 
			}
			dataEdit(obj).then(res=>{
				if(res.data.status === 200 ){
					this.$message.success('操作成功');
					this.$emit('visibleCancel',true);
				}else{
					this.$message.error(res.data.msg);
				}
				this.loading = false;
			})
		},
    handleCancel() {
			this.loading = false;
      this.$emit('visibleCancel');
		},
		// 获取列表
    departmentListFun(){
      departmentList().then(res =>{
        if(res.data.status === 200){
					console.log(1111);
          this.departmentList = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
  },
};
</script>
